@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .btn {
    @apply rounded-sm focus:outline-none focus:ring-1 text-center hover:shadow-sm px-4 py-[6px];
  }

  .btn-sm {
    @apply px-3 py-1 text-xs;
  }

  .btn-primary-disabled {
    @apply bg-indigo-500 hover:bg-indigo-400 text-white;
  }

  .btn-primary {
    @apply bg-indigo-600 text-white focus:ring-indigo-500 hover:bg-indigo-500;
  }

  .btn-danger-disabled {
    @apply bg-red-500 hover:bg-red-400 text-white;
  }

  .btn-danger {
    @apply bg-red-600 text-white focus:ring-red-500 hover:bg-red-500;
  }

  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply focus:border-indigo-500 focus:ring-indigo-500 focus:rounded-sm border border-gray-300 rounded-sm shadow-sm w-full px-3 py-1;
  }

  [type="checkbox"],
  [type="radio"] {
    @apply rounded border-gray-300 text-indigo-600 focus:ring-indigo-500;
  }

  [type="radio"] {
    @apply rounded-full;
  }

  table.table {
    @apply table-auto border-collapse border border-gray-400 w-full;
  }

  table.table thead tr {
    @apply bg-gray-100 text-gray-500 font-medium text-left;
  }

  table.table td,
  table.table th {
    @apply p-2;
  }

  table.table-hover tbody tr {
    @apply hover:bg-gray-100;
  }

  table.table-border td,
  table.table-border th {
    @apply border border-gray-300;
  }
}

.mapboxgl-popup-close-button {
  right: 5px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #4f46e5;
}

.react-datepicker__header {
  background-color: #e5e7eb;
  border-bottom: none;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #4b5563;
}

.react-datepicker__navigation {
  top: 10px;
}

#custom-map {
  width: 100%;
  height: 100%;
}
